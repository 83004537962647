@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button {
  background-color: #3f51b5;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
}
.button:hover {
  background-color: #313d8a;
}

.buttonSave {
  background-color: #3f51b5;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin: 20px 2px;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
}
.buttonSave:hover {
  background-color: #313d8a;
}
.buttondel {
  background-color: #e00101;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin: 20px 2px;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
}
.BANKCOLOR {
  padding: 5px 10px;
  margin: 30px 2px;
  color: white;
  display: inline-block;
  border: none;
  border-radius: 8px;
}

.BaColor {
  color: #e00101;
}

.buttondel:hover {
  background-color: #923232;
}
.buttonedit {
  background-color: #ffda05;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin: 20px 2px;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
}
.buttonedit:hover {
  background-color: #83d303;
}
.block {
  float: right;

  float: right;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* //////////////////////////////////////////////// */
.form-control {
  display: block !important;
  width: 100% !important;
  height: 38px !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  line-height: 1.42857143 !important;
  color: #333 !important;
  background-color: #fff !important;
  background-image: none !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s !important;
}

.is-invalid {
  border: 1px solid red !important;
}

.is-valid {
  border: 1px solid rgb(2, 170, 67) !important;
}

/* ///////////////////////////////////////////////////////////////////////// */

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
  border-style: dotted;
  border-width: 10px;
}

div.border {
  border-style: solid;
  border-width: 1px;
  border-color: rgb(255, 0, 0);
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.input-feedback {
  color: red;
  font-size: 14px;
  font-family: "kanit";
  margin-top: 0.25rem;
}

.btn-warning {
  background-color: #ff9800;
}

table,
td,
th {
  border: 1px solid black;
  padding: 3px;
}
th {
  font-family: "kanit";
  font-size: 13px;
}
td {
  /* border: 1px solid black; */
  font-family: "kanit";
  font-size: 10px;
}

.tdRemove {
  border: 0px solid black;
  font-family: "kanit";
  font-size: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.headtable {
  width: 600px;
  text-align: center;
  font-size: 11px;
}
.bodytable {
  width: 400px;
  text-align: center;
  font-size: 11px;
}

/* /* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


*/ body {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  background: #ed5533;
  height: 100vh;
  overflow: hidden;
  position: absolute;
}

h1 {
  font-size: 3.5em;
  text-align: center;
}
.layer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 0.8s;
  transform: perspective(800px) scale(1) translateX(0) rotateY(0);

  transform-style: preserve-3d;
}
nav {
  vertical-align: center;
}

nav ul {
  padding-right: 65%;
  line-height: 2;
}
nav ul ui {
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  color: aliceblue;
}

.front-page {
  background: #55707f;
}

body:hover .front-page {
  transform: perspective(800px) scale(0.75) translateY(-1.66%) rotateY(75deg);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img-login {
  width: 50%;
  height: 50%;
}

